
html, body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}
.error-message {
    color: red;
  }

  .calmag-logo{
    margin-left:13px
  }

  .logo-container {
    display: flex;
    justify-content: center; /* Horizontally center the content */
    align-items: center; /* Vertically center the content */
    margin: 0 auto; /* Center the container itself horizontally */
    max-width: 100%; /* Ensure the image doesn't exceed its container width */
}